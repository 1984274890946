import "./Css/global.css";
import "./App.css"
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import ThemeContextProvider from './Context/ThemeContext';
import { useEffect, useState,Suspense, lazy} from "react";
import DataContextProvider from "./Context/DataContext";
import GoogleOneTap from 'google-one-tap'
import { AuthProvider } from "./Components/auth/BasicAuthContext"; // Context for modal
import BasicAuthPopup from "./Components/auth/BasicAuthPopup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import HomePageSketon from "./Pages/SketonLoading/HomePageSketon"
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Lazy loaded components
const NotFoundPage = lazy(() => import('./Pages/NotFoundPage'));
const Footer = lazy(() => import('./Components/Footer'));
const NavBar = lazy(() => import('./Components/NavBar'));
const HomePage = lazy(() => import('./Pages/HomePage'));
const SearchResults = lazy(() => import("./Pages/SearchResults"));
const IndividualHotel = lazy(() => import("./Pages/IndividualHotel"));
const CheckOut = lazy(() => import("./Pages/CheckOut"));
const CompanyDetails = lazy(() => import("./Pages/CompanyDetails"));
const PaymentStatus = lazy(() => import("./Pages/PaymentStatus"));
const PaymentStatusupi = lazy(() => import("./Pages/upiPayementStatus"));
const TermCondition = lazy(() => import("./Pages/TermCondition"));
const ContactUs = lazy(() => import("./Pages/AboputComapany"));
const MakeReservation = lazy(() => import("./Pages/MakeReservation"));
const BussinessPolicy = lazy(() => import("./Pages/BussinesPolicy"));
const ConnectivityCenter = lazy(() => import("./Pages/ConnectivityCenter"));
const AboutCompany = lazy(() => import("./Pages/AboutCompany"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy"));
const GuestBookingPolicy = lazy(() => import("./Pages/GuestBookingPolicy"));
const TrustAndSafety = lazy(() => import("./Pages/TrustAndSafety"));
const CyberSequrity = lazy(() => import("./Pages/CyberSequrity"));
const SignupPage = lazy(() => import('./Pages/Signup'));
const LoginPage = lazy(() => import('./Pages/Login'));
const ForgotPassword = lazy(() => import('./Pages/forgotPassword'));
const LoginOtp = lazy(() => import('./Pages/OtpLogin'));
const UpdateUser = lazy(() => import('./Pages/UserProfile'));
const ChnagePassword = lazy(() => import('./Pages/ChnagePassword'));
const GDPRCompliance = lazy(() => import('./Pages/GDPRCompliance'));
const GoogleListingPage = lazy( () => import('./Pages/GoogleListingPage'));
const googleOneTapConfig = {
  client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,//required
  auto_select: false,//optional
  cancel_on_tap_outside: false,//optional
  context: 'signin',//optionalaaaa
};

function App() {
  const [loginData, setLoginData] = useState(
    localStorage.getItem("loginData") ? JSON.parse(localStorage.getItem("loginData")) : null
  )
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );
   
 

  useEffect(() => {
    if (!loginData) {
      GoogleOneTap(googleOneTapConfig, async (response) => {
        setLoginData(response);
        localStorage.setItem("loginData", JSON.stringify(response))
      })
    }
  }, [loginData])
  

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (event) => {
      setIsDarkMode(event.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    const body = document.body;

    if (isDarkMode) {
      body.classList.add('darkmode');
    } else {
      body.classList.remove('darkmode');
    }
  }, [isDarkMode]);
  return (
    <ThemeContextProvider>
      <DataContextProvider>
        <BrowserRouter scrollRestoration={false}>
          <MainRouter />
        </BrowserRouter>
      </DataContextProvider>

    </ThemeContextProvider>
  );
}

function handleLogout() {
  localStorage.removeItem("loginData");
  //setLoginData(null);
}

function MainRouter() {
  const [prevPath, setPrevPath] = useState('');
  const location = useLocation();

  useEffect(() => {
    setPrevPath(location.pathname);
  }, [location.pathname]);
  
  const infoTokenandOrder = {
    token: 'your_token_here',
    orderId: 'your_order_id_here'
  };
  return (
    <div className="App">
      <AuthProvider> 
      <Suspense fallback={<HomePageSketon />}>
      {window.location.pathname !== "/hotel" && <NavBar />}
      <TransitionGroup>
            <CSSTransition key={location.key} classNames="page" timeout={300}>
          <Routes location={location}>
            <Route path="user/change-password" element={<ChnagePassword />} />
            <Route path="user/forgot-password" element={<ForgotPassword />} />
            <Route path="user/otp-login" element={<LoginOtp />} />
            <Route path="user/login" element={<LoginPage />} />
            <Route path="user/update-userprofile" element={<UpdateUser />} />
            <Route path="user/signup" element={<SignupPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/cyber-sequrity" element={<CyberSequrity />} />
            <Route path="/trust-and-safety" element={<TrustAndSafety />} />
            <Route path="/guest-booking-policy" element={<GuestBookingPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/TermCondition" element={<TermCondition />} />
            <Route path="/bussiness-policy" element={<BussinessPolicy />} />
            <Route path="/makeReservation" element={<MakeReservation />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/connectivity-center" element={<ConnectivityCenter />} />
            <Route path="/about-company" element={<AboutCompany />} />
            <Route path="/payment-status" element={<PaymentStatus tokenAndOderId={infoTokenandOrder} />} />
            <Route path="/search-results" element={<SearchResults />} />
            <Route path="/hotel" element={<IndividualHotel />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/company-details" element={<CompanyDetails />} />
            <Route path="/payments-status" element={<PaymentStatusupi />} />
            <Route path="/GDPR-Compliance" element={<GDPRCompliance />} />
            <Route path="/hotel-details/:listingId.html" element={<GoogleListingPage />} />
          </Routes>
          </CSSTransition>
          </TransitionGroup>
        <BasicAuthPopup />
         {/* <Footer /> */}
         {window.location.pathname !== "/hotel" && <Footer />}
         </Suspense>
      </AuthProvider>
      <ToastContainer/>
    </div>
  );
}

export default App;
