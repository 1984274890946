import React, { createContext, useContext, useState } from "react";

const BasicAuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    return (
      <BasicAuthContext.Provider value={{ isModalOpen, openModal, closeModal }}>
        {children}
      </BasicAuthContext.Provider>
    );
  };
  
  export const useModal = () => {
    return useContext(BasicAuthContext);
  };
