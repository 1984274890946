import React,{useEffect} from "react";
import { useModal } from "./BasicAuthContext";
import { Container, Image, Modal, Navbar, Offcanvas,Button, CloseButton } from 'react-bootstrap';
import "../../Css/AuthPopup.css";
import { FcCloseUpMode, FcGoogle } from "react-icons/fc";
import {AiOutlineClose} from "react-icons/ai";
import { FacebookIcon } from "react-share";

const BasicAuthPopup = () => {
    const { isModalOpen, closeModal } = useModal();
  
    useEffect(() => {
        // Add or remove the "modal-open" class to the body based on modal state
        if (isModalOpen) {
          document.body.style.overflow = "hidden"; // Prevent scrolling
        } else {
          document.body.style.overflow = "unset"; // Allow scrolling
        }
    
        // Clean up the style when the component unmounts
        return () => {
          document.body.style.overflow = "unset"; // Restore scrolling
        };
      }, [isModalOpen]);

      
    if (!isModalOpen) return null;
  
    return (
      <div className="modal-container">
        <div className="modal-content">
            <AiOutlineClose className="close-button" onClick={closeModal}/>
          {/* Add your modal content here */}
          <h2>Welcome!</h2>
          <p>Sign in with your social account:</p>
          <div className="modal-buttons top-buttons">
            <button className="google-button">
                    <FcGoogle size={21}/> <span>Continue with Google</span>
            </button>
            <button className="facebook-button">
                <FacebookIcon size={21} borderRadius={5}/><span>Continue with Facebook</span>
            </button>
        </div>
        </div>
      </div>
    );
  };
  
export default BasicAuthPopup;
  
  
  
  
  
  